import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { SearchAllStudent, SearchStudentFilter } from "../network/Network";
import { SpinnerCircular } from "spinners-react";

function SearchPage() {
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [program, setProgram] = useState("");
  const [searchSpinLoad, setSearchSpinLoad] = useState(true);

  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentData, setStudentData] = useState([]);
  
  // Get Products
  useEffect(() => {
    setSearchSpinLoad(true);
    SearchAllStudent(currentPage)
      .then((res) => {
        console.log(res);
        setStudentData(res?.data?.response?.data?.data);
        setSearchSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        setSearchSpinLoad(false);
      });
  }, [currentPage]);

  const SubmitSearch = (event) => {
    event.preventDefault();
    setSearchSpinLoad(true);
    let data = new FormData();
    data.append("year", search);
    data.append("program", program);
    data.append("name", name);
    console.log(data);
    SearchStudentFilter(currentPage,data)
      .then((res) => {
        console.log(res);
        setStudentData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSearchSpinLoad(false);
      })
      .catch((err) => {
        console.log(err?.response?.data);
        toast.error(err?.response?.data?.message);
        setSearchSpinLoad(false);
      });
    }   

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  return (
    <>
      <div className="searchPage">
        <div className="container">
          <div className="search-bar">
            {/* <select
              className="selectProgram"
                onChange={(e) => {
                  setProgram(e.target.value);
                }}
            >
                  <option selected>Select Program</option>
                  <option value={"MSPM"}>MSPM</option>
                  <option value={"MSPH"}>MSPH</option>
                  <option value={"MSPS"}>MSPS</option>
                  <option value={"MSPD"}>MSPD</option>
                  <option value={"MoA"}>MoA</option>
                  <option value={"MBA"}>MBA</option>
              </select> */}
                   
                   
              <input 
                type="text" 
                placeholder="Program" 
                value={program}
                className="mb-2"
                onChange={(e) => setProgram(e.target.value)}
              />
              <input 
                type="number" 
                placeholder="Year" 
                min="2017" 
                max="2099" 
                value={search}
                className="mb-2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <input 
                type="text" 
                placeholder="Student Name" 
                value={name}
                className="mb-2"
                onChange={(e) => setName(e.target.value)}
              />
            <button
              className="btn mb-2 search-btn"
              onClick={(e) => SubmitSearch(e)}
            >
              Search
            </button>
          </div>
          {
            searchSpinLoad ? ( 
              <div className="loader-container">
              <SpinnerCircular size="100px" color="#8dc63e" />
            </div>
            ) : (
              <>
                {studentData.length > 0? (
              <>
                  <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {studentData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>
                        <Link to={`/profile/${item?.name}`} state={{ data: item }} style={{color:"#fff"}}>
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
                </>
              ) : (
                <p className="noRecord">
                  No Record Found...!!
                </p>
              )}
              </>           
            )
          }
         
          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<<"
              nextLabel=">>"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
          <div>        
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
