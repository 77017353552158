import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from "../pages/Profile";
import SearchPage from "../pages/SearchPage";

function BasicRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="profile/:Id" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default BasicRoutes;
