import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './App.css';
import './assets/css/Style.css'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BasicRoutes from './routes/BasicRoutes';

function App() {

  return (
    <>
      <BasicRoutes />
    <ToastContainer />
  
    </>
   
  );
}

export default App;
