import {
  GET_STUDENT,
  SHOW,
  PAGE, 
  } from "../network/Endpoint";
  
  import {
    doGet,
    doPost,
    doPatch,
    doDelete,
    doPostProfilePictureUpload,
  } from "./Config";
  

  export const SearchAllStudent = (currentPage) => {
    return doPost(`${GET_STUDENT}${PAGE}=${currentPage}`,);
  };

  export const SearchStudentFilter = (currentPage,data) => {
    return doPost(`${GET_STUDENT}${PAGE}=${currentPage}`,data);
  };


  