import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ImageUrl } from '../network/ApiUrl'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCakeCandles,
    faLocationDot,
    faPhone,
    faEnvelope,
    faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
function Profile() {
    const Navigate = useNavigate()
    const location = useLocation()
    console.log(location)
    const StudentData = location.state.data
  return (
    <>
        <div className="profile">
            <div className='container'>
            <div className='back' 
                    onClick={()=>Navigate(-1)}
                >
                    <FontAwesomeIcon className="orange mr-2 iconback" icon={faArrowLeftLong} />
                </div>
                <div className='innercontainer'>
                    <h2 className='title'>Profile</h2>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='profile-pic'>
                                <img src={`${ImageUrl}/${StudentData?.image}`} alt="" className='image-fluid image-width' />
                            </div>
                            <div className='info-container'>
                                <p className='info-text'><FontAwesomeIcon className="orange mr-2" icon={faCakeCandles} /> {StudentData?.dob}</p>
                                <p className='info-text'><FontAwesomeIcon className="orange mr-2" icon={faLocationDot} /> {StudentData?.address}</p>
                                <p className='info-text'><FontAwesomeIcon className="orange mr-2" icon={faPhone} /> {StudentData?.phone}</p>
                                <p className='info-text'><FontAwesomeIcon className="orange mr-2" icon={faEnvelope} /> {StudentData?.email}</p>
                                <p className='info-text'><FontAwesomeIcon className="orange mr-2" icon={faEnvelope} /> {StudentData?.linkedin}</p>
                            </div>
                        </div>    
                        <div className='col-md-9'>
                            <h3 className='name'>{StudentData?.name}</h3>
                            <p className='desc'>
                            {StudentData?.bio}
                            </p>
                            <div className='edu-container'>
                                <h3 className='subTitle'>Education</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">institute</th>
                                        <th scope="col">Degree</th>
                                        <th scope="col">Grade</th>
                                        <th scope="col">Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {StudentData?.qualification?.map((item,index)=>{
                                            return(
                                                <tr key={index}>
                                                <td>{item?.institute}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.grade}</td>
                                                <td>{item?.year}</td>
                                                </tr>       
                                            )
                                        })}                                                             
                                    </tbody>
                                    </table>
                            </div>
                            <div className='edu-container'>
                                <h3 className='subTitle'>Experience</h3>
                                <ul>
                                    {StudentData?.experience?.map((item,index)=>{
                                            return(
                                                <li key={index}>{item?.course}</li>
                                            )
                                        })
                                       }
                                </ul>             
                            </div>
                            <div className='edu-container'>
                                <h3 className='subTitle'>Skills</h3>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ul>
                                                <h4 className='subtitle'>Technical Skills</h4>
                                                {Array.isArray(JSON.parse(StudentData?.technical_skills)) ? (
                                                    <>
                                                        {JSON.parse(StudentData?.technical_skills)?.map((item,index)=>{
                                                        return(
                                                            <li className='ml-5' key={index}>{item}</li>
                                                            )
                                                            })
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                     <li className='ml-5'>{StudentData?.technical_skills}</li>
                                                    </>
                                                )}
                                                
                                            </ul>
                                        </div>
                                        <div className='col-md-6'>
                                            <ul>
                                                <h4 className='subtitle'>Soft Skills</h4>
                                               {Array.isArray(JSON.parse(StudentData?.soft_skills)) ? (
                                                   <>
                                                    {JSON.parse(StudentData?.soft_skills)?.map((item,index)=>{
                                                        return(
                                                            <li className='ml-5' key={index}>{item}</li>
                                                            )
                                                        })
                                                    }
                                                   </>
                                               ) : (
                                                <li className='ml-5'>{StudentData?.soft_skills}</li>
                                               )}
                                                
                                            </ul>   
                                        </div>
                                    </div>
                            </div>
                            <div className='edu-container'>
                                <h3 className='subTitle'>Achievement</h3>
                                <ul>
                                    {StudentData?.achievement?.map((item,index)=>{
                                            return(
                                                <li key={index}>{item?.achievement}</li>
                                            )
                                        })
                                       }
                                </ul>    
                            </div>
                            <div className='edu-container'>
                                <h3 className='subTitle'>Elective</h3>
                                <ul>
                                    {StudentData?.elective?.map((item,index)=>{
                                            return(
                                                <li key={index}>{item?.course}</li>
                                            )
                                        })
                                    }
                                </ul>    
                            </div>                           
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Profile